#eOE0XA4Tg8Y92_ts {
  animation: eOE0XA4Tg8Y92_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eOE0XA4Tg8Y92_ts__ts {
  0% {
    transform: translate(97.31078px, 120.810005px) scale(1, 1);
  }
  50% {
    transform: translate(97.31078px, 120.810005px) scale(0.5, 0.5);
  }
  100% {
    transform: translate(97.31078px, 120.810005px) scale(1, 1);
  }
}

#eOE0XA4Tg8Y130_tr {
  animation: eOE0XA4Tg8Y130_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eOE0XA4Tg8Y130_tr__tr {
  0% {
    transform: translate(80.43394px, 225.203px) rotate(0deg);
  }
  50% {
    transform: translate(80.43394px, 225.203px) rotate(15deg);
  }
  100% {
    transform: translate(80.43394px, 225.203px) rotate(0deg);
  }
}

#eOE0XA4Tg8Y668_to {
  animation: eOE0XA4Tg8Y668_to__to 3000ms linear infinite normal forwards;
}
@keyframes eOE0XA4Tg8Y668_to__to {
  0% {
    transform: translate(162.727882px, 130.496464px);
  }
  50% {
    transform: translate(162.727882px, 135.956051px);
  }
  100% {
    transform: translate(162.727882px, 130.496051px);
  }
}

#eOE0XA4Tg8Y677_to {
  animation: eOE0XA4Tg8Y677_to__to 3000ms linear infinite normal forwards;
}
@keyframes eOE0XA4Tg8Y677_to__to {
  0% {
    transform: translate(208.907005px, 145.377685px);
  }
  50% {
    transform: translate(208.907005px, 151.213928px);
  }
  100% {
    transform: translate(208.907005px, 145.373928px);
  }
}

#eOE0XA4Tg8Y682_to {
  animation: eOE0XA4Tg8Y682_to__to 3000ms linear infinite normal forwards;
}
@keyframes eOE0XA4Tg8Y682_to__to {
  0% {
    transform: translate(211.449791px, 78.003304px);
  }
  50% {
    transform: translate(238.299px, 94.222606px);
  }
  100% {
    transform: translate(211.352176px, 78.048046px);
  }
}

#eOE0XA4Tg8Y726_to {
  animation: eOE0XA4Tg8Y726_to__to 3000ms linear infinite normal forwards;
}
@keyframes eOE0XA4Tg8Y726_to__to {
  0% {
    transform: translate(27.854897px, 96.405404px);
  }
  50% {
    transform: translate(27.854897px, 101.56607px);
  }
  100% {
    transform: translate(27.854897px, 96.40607px);
  }
}

#eOE0XA4Tg8Y759_to {
  animation: eOE0XA4Tg8Y759_to__to 3000ms linear infinite normal forwards;
}
@keyframes eOE0XA4Tg8Y759_to__to {
  0% {
    transform: translate(293.251343px, 244.196762px);
  }
  50% {
    transform: translate(293.251343px, 249.743027px);
  }
  100% {
    transform: translate(293.251343px, 244.193027px);
  }
}

#eOE0XA4Tg8Y868_ts {
  animation: eOE0XA4Tg8Y868_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eOE0XA4Tg8Y868_ts__ts {
  0% {
    transform: translate(169.195px, 269.707535px) scale(1, 1);
  }
  50% {
    transform: translate(169.195px, 269.707535px) scale(0.5, 0.5);
  }
  100% {
    transform: translate(169.195px, 269.707535px) scale(1, 1);
  }
}
