#eHIyS0W2N6722_to {
  animation: eHIyS0W2N6722_to__to 3000ms linear infinite normal forwards;
}
@keyframes eHIyS0W2N6722_to__to {
  0% {
    transform: translate(300.226501px, 59.5291px);
  }
  50% {
    transform: translate(270.524501px, 78.235653px);
  }
  100% {
    transform: translate(300.230501px, 59.525806px);
  }
}
#eHIyS0W2N67180_ts {
  animation: eHIyS0W2N67180_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eHIyS0W2N67180_ts__ts {
  0% {
    transform: translate(291.894745px, 152.921524px) scale(1, 1);
  }
  50% {
    transform: translate(291.894745px, 152.921524px) scale(1.5, 1.5);
  }
  100% {
    transform: translate(291.894745px, 152.921524px) scale(1, 1);
  }
}
#eHIyS0W2N67185_ts {
  animation: eHIyS0W2N67185_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eHIyS0W2N67185_ts__ts {
  0% {
    transform: translate(197.264px, 108.980822px) scale(1, 1);
  }
  50% {
    transform: translate(197.264px, 108.980822px) scale(1.3, 1.3);
  }
  100% {
    transform: translate(197.264px, 108.980822px) scale(1, 1);
  }
}
