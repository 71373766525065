#eXpAk5iHRSW4_tr {
  animation: eXpAk5iHRSW4_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eXpAk5iHRSW4_tr__tr {
  0% {
    transform: translate(166.58551px, 220.675199px) rotate(0deg);
  }
  50% {
    transform: translate(166.58551px, 220.675199px) rotate(-10deg);
  }
  100% {
    transform: translate(166.58551px, 220.675199px) rotate(0deg);
  }
}
#eXpAk5iHRSW28 {
  animation: eXpAk5iHRSW28_c_o 3000ms linear infinite normal forwards;
}
@keyframes eXpAk5iHRSW28_c_o {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
#eXpAk5iHRSW67_tr {
  animation: eXpAk5iHRSW67_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eXpAk5iHRSW67_tr__tr {
  0% {
    transform: translate(208.092px, 141.987638px) rotate(0deg);
  }
  50% {
    transform: translate(208.092px, 141.987638px) rotate(-15deg);
  }
  100% {
    transform: translate(208.092px, 141.987638px) rotate(0deg);
  }
}
#eXpAk5iHRSW89_ts {
  animation: eXpAk5iHRSW89_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eXpAk5iHRSW89_ts__ts {
  0% {
    transform: translate(109.648998px, 272.183502px) scale(1, 1);
  }
  50% {
    transform: translate(109.648998px, 272.183502px) scale(1.5, 1.5);
  }
  100% {
    transform: translate(109.648998px, 272.183502px) scale(1, 1);
  }
}
#eXpAk5iHRSW181_tr {
  animation: eXpAk5iHRSW181_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eXpAk5iHRSW181_tr__tr {
  0% {
    transform: translate(132.904631px, 206.004471px) rotate(0deg);
  }
  50% {
    transform: translate(132.904631px, 206.004471px) rotate(13deg);
  }
  100% {
    transform: translate(132.904631px, 206.004471px) rotate(0deg);
  }
}
