#elCtLVRwAfp151_to {
  animation: elCtLVRwAfp151_to__to 6000ms linear infinite normal forwards;
}

@keyframes elCtLVRwAfp151_to__to {
  0% {
    offset-distance: 0%;
  }
  33.33333% {
    offset-distance: 36.035088%;
  }
  66.666667% {
    offset-distance: 63.052781%;
  }
  100% {
    offset-distance: 100%;
  }
}
