#eDaPwc17Q0V49_ts {
  animation: eDaPwc17Q0V49_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eDaPwc17Q0V49_ts__ts {
  0% {
    transform: translate(130.839005px, 72.545026px) scale(1, 1);
  }
  50% {
    transform: translate(130.839005px, 72.545026px) scale(1.3, 1.3);
  }
  100% {
    transform: translate(130.839005px, 72.545026px) scale(1, 1);
  }
}
#eDaPwc17Q0V51_ts {
  animation: eDaPwc17Q0V51_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eDaPwc17Q0V51_ts__ts {
  0% {
    transform: translate(144.797005px, 64.000075px) scale(1, 1);
  }
  50% {
    transform: translate(144.797005px, 64.000075px) scale(1.3, 1.3);
  }
  100% {
    transform: translate(144.797005px, 64.000075px) scale(1, 1);
  }
}
#eDaPwc17Q0V53_ts {
  animation: eDaPwc17Q0V53_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eDaPwc17Q0V53_ts__ts {
  0% {
    transform: translate(130.839005px, 84.418701px) scale(1, 1);
  }
  50% {
    transform: translate(130.839005px, 84.418701px) scale(1.3, 1.3);
  }
  100% {
    transform: translate(130.839005px, 84.418701px) scale(1, 1);
  }
}
#eDaPwc17Q0V55_ts {
  animation: eDaPwc17Q0V55_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eDaPwc17Q0V55_ts__ts {
  0% {
    transform: translate(144.797005px, 75.874203px) scale(1, 1);
  }
  50% {
    transform: translate(144.797005px, 75.874203px) scale(1.3, 1.3);
  }
  100% {
    transform: translate(144.797005px, 75.874203px) scale(1, 1);
  }
}
#eDaPwc17Q0V57_ts {
  animation: eDaPwc17Q0V57_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eDaPwc17Q0V57_ts__ts {
  0% {
    transform: translate(130.839005px, 96.04268px) scale(1, 1);
  }
  50% {
    transform: translate(130.839005px, 96.04268px) scale(1.3, 1.3);
  }
  100% {
    transform: translate(130.839005px, 96.04268px) scale(1, 1);
  }
}
#eDaPwc17Q0V59_ts {
  animation: eDaPwc17Q0V59_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eDaPwc17Q0V59_ts__ts {
  0% {
    transform: translate(144.797005px, 87.49753px) scale(1, 1);
  }
  50% {
    transform: translate(144.797005px, 87.49753px) scale(1.3, 1.3);
  }
  100% {
    transform: translate(144.797005px, 87.49753px) scale(1, 1);
  }
}
#eDaPwc17Q0V61_ts {
  animation: eDaPwc17Q0V61_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eDaPwc17Q0V61_ts__ts {
  0% {
    transform: translate(135.568001px, 107.867948px) scale(1, 1);
  }
  50% {
    transform: translate(135.568001px, 107.867948px) scale(1.3, 1.3);
  }
  100% {
    transform: translate(135.568001px, 107.867948px) scale(1, 1);
  }
}
#eDaPwc17Q0V63_ts {
  animation: eDaPwc17Q0V63_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eDaPwc17Q0V63_ts__ts {
  0% {
    transform: translate(143.442001px, 103.325327px) scale(1, 1);
  }
  50% {
    transform: translate(143.442001px, 103.325327px) scale(1.3, 1.3);
  }
  100% {
    transform: translate(143.442001px, 103.325327px) scale(1, 1);
  }
}
#eDaPwc17Q0V65_ts {
  animation: eDaPwc17Q0V65_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eDaPwc17Q0V65_ts__ts {
  0% {
    transform: translate(151.314003px, 98.780874px) scale(1, 1);
  }
  50% {
    transform: translate(151.314003px, 98.780874px) scale(1.3, 1.3);
  }
  100% {
    transform: translate(151.314003px, 98.780874px) scale(1, 1);
  }
}
#eDaPwc17Q0V97_to {
  animation: eDaPwc17Q0V97_to__to 3000ms linear infinite normal forwards;
}
@keyframes eDaPwc17Q0V97_to__to {
  0% {
    transform: translate(131.618698px, 180.167435px);
  }
  50% {
    transform: translate(110.648575px, 192.165438px);
  }
  100% {
    transform: translate(131.762px, 180.164046px);
  }
}
#eDaPwc17Q0V102_to {
  animation: eDaPwc17Q0V102_to__to 3000ms linear infinite normal forwards;
}
@keyframes eDaPwc17Q0V102_to__to {
  0% {
    transform: translate(242.378975px, 211.526001px);
  }
  50% {
    transform: translate(279.695521px, 190.673002px);
  }
  100% {
    transform: translate(242.378979px, 211.527998px);
  }
}
#eDaPwc17Q0V150_tr {
  animation: eDaPwc17Q0V150_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eDaPwc17Q0V150_tr__tr {
  0% {
    transform: translate(237.139984px, 95.6773px) rotate(0deg);
  }
  50% {
    transform: translate(237.139984px, 95.6773px) rotate(15deg);
  }
  100% {
    transform: translate(237.139984px, 95.6773px) rotate(0deg);
  }
}
#eDaPwc17Q0V243_tr {
  animation: eDaPwc17Q0V243_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eDaPwc17Q0V243_tr__tr {
  0% {
    transform: translate(111.968727px, 236.501815px) rotate(0deg);
  }
  50% {
    transform: translate(111.968727px, 236.501815px) rotate(8deg);
  }
  100% {
    transform: translate(111.968727px, 236.501815px) rotate(0deg);
  }
}
