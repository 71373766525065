#evNFUCgao6R2_tk {
  animation: evNFUCgao6R2_tk__tk 3000ms linear infinite normal forwards;
}
@keyframes evNFUCgao6R2_tk__tk {
  0% {
    transform: translate(179.714996px, 128.638996px) skewX(0deg) skewY(0deg);
  }
  50% {
    transform: translate(179.714996px, 128.638996px) skewX(5deg) skewY(5deg);
  }
  100% {
    transform: translate(179.714996px, 128.638996px) skewX(0deg) skewY(0deg);
  }
}

#evNFUCgao6R7_tk {
  animation: evNFUCgao6R7_tk__tk 3000ms linear infinite normal forwards;
}
@keyframes evNFUCgao6R7_tk__tk {
  0% {
    transform: translate(121.414502px, 161.5066px) skewX(0deg) skewY(0deg);
  }
  50% {
    transform: translate(121.414502px, 161.5066px) skewX(5deg) skewY(5deg);
  }
  100% {
    transform: translate(121.414502px, 161.5066px) skewX(0deg) skewY(0deg);
  }
}

#evNFUCgao6R11_tk {
  animation: evNFUCgao6R11_tk__tk 3000ms linear infinite normal forwards;
}
@keyframes evNFUCgao6R11_tk__tk {
  0% {
    transform: translate(162.089996px, 150.687996px) skewX(0deg) skewY(0deg);
  }
  50% {
    transform: translate(162.089996px, 150.687996px) skewX(5deg) skewY(5deg);
  }
  100% {
    transform: translate(162.089996px, 150.687996px) skewX(0deg) skewY(0deg);
  }
}

#evNFUCgao6R15_tk {
  animation: evNFUCgao6R15_tk__tk 3000ms linear infinite normal forwards;
}
@keyframes evNFUCgao6R15_tk__tk {
  0% {
    transform: translate(213.0205px, 129.647499px) skewX(0deg) skewY(0deg);
  }
  50% {
    transform: translate(213.0205px, 129.647499px) skewX(5deg) skewY(5deg);
  }
  100% {
    transform: translate(213.0205px, 129.647499px) skewX(0deg) skewY(0deg);
  }
}

#evNFUCgao6R24 {
  animation: evNFUCgao6R24_c_o 3000ms linear infinite normal forwards;
}
@keyframes evNFUCgao6R24_c_o {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.35;
  }
  100% {
    opacity: 1;
  }
}

#evNFUCgao6R33_ts {
  animation: evNFUCgao6R33_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes evNFUCgao6R33_ts__ts {
  0% {
    transform: translate(88.417578px, 219.972923px) scale(1, 1);
  }
  50% {
    transform: translate(88.417578px, 219.972923px) scale(1.1, 1.1);
  }
  100% {
    transform: translate(88.417578px, 219.972923px) scale(1, 1);
  }
}

#evNFUCgao6R137_ts {
  animation: evNFUCgao6R137_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes evNFUCgao6R137_ts__ts {
  0% {
    transform: translate(286.463738px, 191.109963px) scale(1, 1);
  }
  50% {
    transform: translate(286.463738px, 191.109963px) scale(1.1, 1.1);
  }
  100% {
    transform: translate(286.463738px, 191.109963px) scale(1, 1);
  }
}

#evNFUCgao6R145_ts {
  animation: evNFUCgao6R145_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes evNFUCgao6R145_ts__ts {
  0% {
    transform: translate(254.852997px, 246.891213px) scale(1, 1);
  }
  50% {
    transform: translate(254.852997px, 246.891213px) scale(1.1, 1.1);
  }
  100% {
    transform: translate(254.852997px, 246.891213px) scale(1, 1);
  }
}

#evNFUCgao6R197_tk {
  animation: evNFUCgao6R197_tk__tk 3000ms linear infinite normal forwards;
}
@keyframes evNFUCgao6R197_tk__tk {
  0% {
    transform: translate(306.015px, 236.239998px) skewX(0deg) skewY(0deg);
  }
  50% {
    transform: translate(306.015px, 236.239998px) skewX(5deg) skewY(5deg);
  }
  100% {
    transform: translate(306.015px, 236.239998px) skewX(0deg) skewY(0deg);
  }
}
