#eE86QzHTFuO11_tr {
  animation: eE86QzHTFuO11_tr__tr 3000ms linear infinite normal forwards;
}

@keyframes eE86QzHTFuO11_tr__tr {
  0% {
    transform: translate(163.54689px, 134.693143px) rotate(0deg);
  }
  50% {
    transform: translate(163.54689px, 134.693143px) rotate(6deg);
  }
  100% {
    transform: translate(163.54689px, 134.693143px) rotate(0deg);
  }
}

#eE86QzHTFuO17_tr {
  animation: eE86QzHTFuO17_tr__tr 3000ms linear infinite normal forwards;
}

@keyframes eE86QzHTFuO17_tr__tr {
  0% {
    transform: translate(33.682819px, 164.157181px) rotate(0deg);
  }
  50% {
    transform: translate(33.682819px, 164.157181px) rotate(10deg);
  }
  100% {
    transform: translate(33.682819px, 164.157181px) rotate(0deg);
  }
}

#eE86QzHTFuO35_tr {
  animation: eE86QzHTFuO35_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eE86QzHTFuO35_tr__tr {
  0% {
    transform: translate(23.12766px, 144.103882px) rotate(0deg);
  }
  50% {
    transform: translate(23.12766px, 144.103882px) rotate(8deg);
  }
  100% {
    transform: translate(23.12766px, 144.103882px) rotate(0deg);
  }
}

#eE86QzHTFuO36_tr {
  animation: eE86QzHTFuO36_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eE86QzHTFuO36_tr__tr {
  0% {
    transform: translate(15.52px, 149px) rotate(0deg);
  }
  50% {
    transform: translate(15.52px, 149px) rotate(-10deg);
  }
  100% {
    transform: translate(15.52px, 149px) rotate(0deg);
  }
}

#eE86QzHTFuO39_tr {
  animation: eE86QzHTFuO39_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eE86QzHTFuO39_tr__tr {
  0% {
    transform: translate(23.12766px, 144.103882px) rotate(0deg);
  }
  50% {
    transform: translate(23.12766px, 144.103882px) rotate(8deg);
  }
  100% {
    transform: translate(23.12766px, 144.103882px) rotate(0deg);
  }
}

#eE86QzHTFuO40_tr {
  animation: eE86QzHTFuO40_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eE86QzHTFuO40_tr__tr {
  0% {
    transform: translate(92.996895px, 171.451546px) rotate(0deg);
  }
  50% {
    transform: translate(92.996895px, 171.451546px) rotate(5deg);
  }
  100% {
    transform: translate(92.996895px, 171.451546px) rotate(0deg);
  }
}

#eE86QzHTFuO41_tr {
  animation: eE86QzHTFuO41_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eE86QzHTFuO41_tr__tr {
  0% {
    transform: translate(92.589523px, 173.493767px) rotate(0deg);
  }
  50% {
    transform: translate(92.589523px, 173.493767px) rotate(5deg);
  }
  100% {
    transform: translate(92.589523px, 173.493767px) rotate(0deg);
  }
}

#eE86QzHTFuO42_tr {
  animation: eE86QzHTFuO42_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eE86QzHTFuO42_tr__tr {
  0% {
    transform: translate(95.969292px, 178.273781px) rotate(0deg);
  }
  50% {
    transform: translate(95.969292px, 178.273781px) rotate(5deg);
  }
  100% {
    transform: translate(95.969292px, 178.273781px) rotate(0deg);
  }
}

#eE86QzHTFuO44_tr {
  animation: eE86QzHTFuO44_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eE86QzHTFuO44_tr__tr {
  0% {
    transform: translate(96.814651px, 171.472px) rotate(0deg);
  }
  50% {
    transform: translate(96.814651px, 171.472px) rotate(5deg);
  }
  100% {
    transform: translate(96.814651px, 171.472px) rotate(0deg);
  }
}

#eE86QzHTFuO45_tr {
  animation: eE86QzHTFuO45_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eE86QzHTFuO45_tr__tr {
  0% {
    transform: translate(100.238049px, 173.059998px) rotate(0deg);
  }
  50% {
    transform: translate(100.238049px, 173.059998px) rotate(5deg);
  }
  100% {
    transform: translate(100.238049px, 173.059998px) rotate(0deg);
  }
}
#eE86QzHTFuO46_tr {
  animation: eE86QzHTFuO46_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eE86QzHTFuO46_tr__tr {
  0% {
    transform: translate(94.215149px, 177.775002px) rotate(0deg);
  }
  50% {
    transform: translate(94.215149px, 177.775002px) rotate(5deg);
  }
  100% {
    transform: translate(94.215149px, 177.775002px) rotate(0deg);
  }
}
#eE86QzHTFuO47_tr {
  animation: eE86QzHTFuO47_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eE86QzHTFuO47_tr__tr {
  0% {
    transform: translate(91.522991px, 174.069084px) rotate(0deg);
  }
  50% {
    transform: translate(91.522991px, 174.069084px) rotate(5deg);
  }
  100% {
    transform: translate(91.522991px, 174.069084px) rotate(0deg);
  }
}

#eE86QzHTFuO60_tr {
  animation: eE86QzHTFuO60_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eE86QzHTFuO60_tr__tr {
  0% {
    transform: translate(78.083963px, 163.763902px) rotate(0deg);
  }
  50% {
    transform: translate(78.083963px, 163.763902px) rotate(4deg);
  }
  100% {
    transform: translate(78.083963px, 163.763902px) rotate(0deg);
  }
}
#eE86QzHTFuO63_tr {
  animation: eE86QzHTFuO63_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eE86QzHTFuO63_tr__tr {
  0% {
    transform: translate(90.984604px, 188.647682px) rotate(0deg);
  }
  50% {
    transform: translate(90.984604px, 188.647682px) rotate(5deg);
  }
  100% {
    transform: translate(90.984604px, 188.647682px) rotate(0deg);
  }
}
#eE86QzHTFuO64_ts {
  animation: eE86QzHTFuO64_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eE86QzHTFuO64_ts__ts {
  0% {
    transform: translate(251.440956px, 53.999188px) scale(1, 1);
  }
  50% {
    transform: translate(251.440956px, 53.999188px) scale(1.15, 1.15);
  }
  100% {
    transform: translate(251.440956px, 53.999188px) scale(1, 1);
  }
}
