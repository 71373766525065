#eYXFALrdCDu2 {
  animation: eYXFALrdCDu2_c_o 3000ms linear infinite normal forwards;
}
@keyframes eYXFALrdCDu2_c_o {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#eYXFALrdCDu18_to {
  animation: eYXFALrdCDu18_to__to 3000ms linear infinite normal forwards;
}
@keyframes eYXFALrdCDu18_to__to {
  0% {
    transform: translate(224.927101px, 69.439387px);
  }
  50% {
    transform: translate(224.927101px, 72.787023px);
  }
  100% {
    transform: translate(224.927101px, 69.437023px);
  }
}
#eYXFALrdCDu30_to {
  animation: eYXFALrdCDu30_to__to 3000ms linear infinite normal forwards;
}
@keyframes eYXFALrdCDu30_to__to {
  0% {
    transform: translate(319.777603px, 167.924904px);
  }
  50% {
    transform: translate(319.777603px, 170.797096px);
  }
  100% {
    transform: translate(319.777603px, 167.927096px);
  }
}
#eYXFALrdCDu50_tr {
  animation: eYXFALrdCDu50_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eYXFALrdCDu50_tr__tr {
  0% {
    transform: translate(149.410744px, 225.655051px) rotate(0deg);
  }
  50% {
    transform: translate(149.410744px, 225.655051px) rotate(-15deg);
  }
  100% {
    transform: translate(149.410744px, 225.655051px) rotate(0deg);
  }
}
#eYXFALrdCDu55_tr {
  animation: eYXFALrdCDu55_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eYXFALrdCDu55_tr__tr {
  0% {
    transform: translate(149.733px, 220.807144px) rotate(0deg);
  }
  50% {
    transform: translate(149.733px, 220.807144px) rotate(-15deg);
  }
  100% {
    transform: translate(149.733px, 220.807144px) rotate(0deg);
  }
}
#eYXFALrdCDu58_tr {
  animation: eYXFALrdCDu58_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eYXFALrdCDu58_tr__tr {
  0% {
    transform: translate(148.588px, 223.170001px) rotate(0deg);
  }
  50% {
    transform: translate(148.588px, 223.170001px) rotate(15deg);
  }
  100% {
    transform: translate(148.588px, 223.170001px) rotate(0deg);
  }
}
#eYXFALrdCDu62_tr {
  animation: eYXFALrdCDu62_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eYXFALrdCDu62_tr__tr {
  0% {
    transform: translate(126.73px, 258.349px) rotate(0deg);
  }
  50% {
    transform: translate(126.73px, 258.349px) rotate(6deg);
  }
  100% {
    transform: translate(126.73px, 258.349px) rotate(0deg);
  }
}
#eYXFALrdCDu65_tr {
  animation: eYXFALrdCDu65_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eYXFALrdCDu65_tr__tr {
  0% {
    transform: translate(130.243001px, 260.545197px) rotate(0deg);
  }
  50% {
    transform: translate(130.243001px, 260.545197px) rotate(-6deg);
  }
  100% {
    transform: translate(130.243001px, 260.545197px) rotate(0deg);
  }
}
#eYXFALrdCDu75_tr {
  animation: eYXFALrdCDu75_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eYXFALrdCDu75_tr__tr {
  0% {
    transform: translate(115.785362px, 246.411px) rotate(0deg);
  }
  50% {
    transform: translate(115.785362px, 246.411px) rotate(-15deg);
  }
  100% {
    transform: translate(115.785362px, 246.411px) rotate(0deg);
  }
}
#eYXFALrdCDu79_tr {
  animation: eYXFALrdCDu79_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eYXFALrdCDu79_tr__tr {
  0% {
    transform: translate(118.9365px, 249.747px) rotate(0deg);
  }
  50% {
    transform: translate(118.9365px, 249.747px) rotate(15deg);
  }
  100% {
    transform: translate(118.9365px, 249.747px) rotate(0deg);
  }
}
#eYXFALrdCDu89_tr {
  animation: eYXFALrdCDu89_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eYXFALrdCDu89_tr__tr {
  0% {
    transform: translate(87.70845px, 233.204px) rotate(0deg);
  }
  50% {
    transform: translate(87.70845px, 233.204px) rotate(15deg);
  }
  100% {
    transform: translate(87.70845px, 233.204px) rotate(0deg);
  }
}
#eYXFALrdCDu94_tr {
  animation: eYXFALrdCDu94_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eYXFALrdCDu94_tr__tr {
  0% {
    transform: translate(87.70845px, 228.931001px) rotate(0deg);
  }
  50% {
    transform: translate(87.70845px, 228.931001px) rotate(15deg);
  }
  100% {
    transform: translate(87.70845px, 228.931001px) rotate(0deg);
  }
}
#eYXFALrdCDu98_tr {
  animation: eYXFALrdCDu98_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eYXFALrdCDu98_tr__tr {
  0% {
    transform: translate(88.7577px, 230.477234px) rotate(0deg);
  }
  50% {
    transform: translate(88.7577px, 230.477234px) rotate(-15deg);
  }
  100% {
    transform: translate(88.7577px, 230.477234px) rotate(0deg);
  }
}
#eYXFALrdCDu108_to {
  animation: eYXFALrdCDu108_to__to 3000ms linear infinite normal forwards;
}
@keyframes eYXFALrdCDu108_to__to {
  0% {
    transform: translate(311.491837px, 229.331589px);
  }
  50% {
    transform: translate(311.491837px, 235.331589px);
  }
  100% {
    transform: translate(311.491837px, 229.327585px);
  }
}
#eYXFALrdCDu163_tr {
  animation: eYXFALrdCDu163_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eYXFALrdCDu163_tr__tr {
  0% {
    transform: translate(347.260301px, 200.458px) rotate(0deg);
  }
  50% {
    transform: translate(347.260301px, 200.458px) rotate(-18deg);
  }
  100% {
    transform: translate(347.260301px, 200.458px) rotate(0deg);
  }
}
#eYXFALrdCDu342_tr {
  animation: eYXFALrdCDu342_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eYXFALrdCDu342_tr__tr {
  0% {
    transform: translate(351.620238px, 212.102px) rotate(0deg);
  }
  50% {
    transform: translate(351.620238px, 212.102px) rotate(-12deg);
  }
  100% {
    transform: translate(351.620238px, 212.102px) rotate(0deg);
  }
}
#eYXFALrdCDu523_tr {
  animation: eYXFALrdCDu523_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eYXFALrdCDu523_tr__tr {
  0% {
    transform: translate(272.913548px, 244.211982px) rotate(0deg);
  }
  50% {
    transform: translate(272.913548px, 244.211982px) rotate(15deg);
  }
  100% {
    transform: translate(272.913548px, 244.211982px) rotate(0deg);
  }
}
#eYXFALrdCDu527_tr {
  animation: eYXFALrdCDu527_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eYXFALrdCDu527_tr__tr {
  0% {
    transform: translate(275.198151px, 243.384px) rotate(0deg);
  }
  50% {
    transform: translate(275.198151px, 243.384px) rotate(-15deg);
  }
  100% {
    transform: translate(275.198151px, 243.384px) rotate(0deg);
  }
}
#eYXFALrdCDu531_tr {
  animation: eYXFALrdCDu531_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eYXFALrdCDu531_tr__tr {
  0% {
    transform: translate(278.18866px, 246.789px) rotate(0deg);
  }
  50% {
    transform: translate(278.18866px, 246.789px) rotate(-20deg);
  }
  100% {
    transform: translate(278.18866px, 246.789px) rotate(0deg);
  }
}
#eYXFALrdCDu581_tr {
  animation: eYXFALrdCDu581_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eYXFALrdCDu581_tr__tr {
  0% {
    transform: translate(113.841187px, 201.354px) rotate(0deg);
  }
  50% {
    transform: translate(113.841187px, 201.354px) rotate(-10deg);
  }
  100% {
    transform: translate(113.841187px, 201.354px) rotate(0deg);
  }
}
#eYXFALrdCDu760_to {
  animation: eYXFALrdCDu760_to__to 3000ms linear infinite normal forwards;
}
@keyframes eYXFALrdCDu760_to__to {
  0% {
    transform: translate(123.418999px, 179.927788px);
  }
  50% {
    transform: translate(123.418999px, 184.610573px);
  }
  100% {
    transform: translate(123.418999px, 179.930573px);
  }
}
#eYXFALrdCDu779_tr {
  animation: eYXFALrdCDu779_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eYXFALrdCDu779_tr__tr {
  0% {
    transform: translate(135.14px, 83.7003px) rotate(0deg);
  }
  50% {
    transform: translate(135.14px, 83.7003px) rotate(-15deg);
  }
  100% {
    transform: translate(135.14px, 83.7003px) rotate(0deg);
  }
}
#eYXFALrdCDu790_tr {
  animation: eYXFALrdCDu790_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eYXFALrdCDu790_tr__tr {
  0% {
    transform: translate(138.975937px, 81.654747px) rotate(0deg);
  }
  50% {
    transform: translate(138.975937px, 81.654747px) rotate(-15deg);
  }
  100% {
    transform: translate(138.975937px, 81.654747px) rotate(0deg);
  }
}
#eYXFALrdCDu814_tr {
  animation: eYXFALrdCDu814_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eYXFALrdCDu814_tr__tr {
  0% {
    transform: translate(115.785362px, 90.757439px) rotate(0deg);
  }
  50% {
    transform: translate(115.785362px, 90.757439px) rotate(-13deg);
  }
  100% {
    transform: translate(115.785362px, 90.757439px) rotate(0deg);
  }
}
#eYXFALrdCDu818_tr {
  animation: eYXFALrdCDu818_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eYXFALrdCDu818_tr__tr {
  0% {
    transform: translate(117.449px, 93.951164px) rotate(0deg);
  }
  50% {
    transform: translate(117.449px, 93.951164px) rotate(-15deg);
  }
  100% {
    transform: translate(117.449px, 93.951164px) rotate(0deg);
  }
}
#eYXFALrdCDu824_ts {
  animation: eYXFALrdCDu824_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eYXFALrdCDu824_ts__ts {
  0% {
    transform: translate(125.774876px, 57.446213px) scale(1, 1);
  }
  50% {
    transform: translate(125.774876px, 57.446213px) scale(1.13, 1.13);
  }
  100% {
    transform: translate(125.774876px, 57.446213px) scale(1, 1);
  }
}
#eYXFALrdCDu844_ts {
  animation: eYXFALrdCDu844_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eYXFALrdCDu844_ts__ts {
  0% {
    transform: translate(316.604523px, 121.641896px) scale(1, 1);
  }
  50% {
    transform: translate(316.604523px, 121.641896px) scale(1.35, 1.35);
  }
  100% {
    transform: translate(316.604523px, 121.641896px) scale(1, 1);
  }
}
#eYXFALrdCDu888_to {
  animation: eYXFALrdCDu888_to__to 3000ms linear infinite normal forwards;
}
@keyframes eYXFALrdCDu888_to__to {
  0% {
    transform: translate(86.512402px, 163.717934px);
  }
  50% {
    transform: translate(86.512402px, 166.787072px);
  }
  100% {
    transform: translate(86.512402px, 163.717072px);
  }
}
