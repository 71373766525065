#elv3HKW7FYB24_to {
  animation: elv3HKW7FYB24_to__to 6000ms linear infinite normal forwards;
}
@keyframes elv3HKW7FYB24_to__to {
  0% {
    transform: translate(400.212494px, 36.799129px);
  }
  50% {
    transform: translate(367.246494px, 60.596131px);
  }
  100% {
    transform: translate(400.21px, 36.799207px);
  }
}

#elv3HKW7FYB25_to {
  animation: elv3HKW7FYB25_to__to 6000ms linear infinite normal forwards;
}
@keyframes elv3HKW7FYB25_to__to {
  0% {
    transform: translate(77.571499px, 120.873913px);
  }
  50% {
    transform: translate(44.605499px, 144.670913px);
  }
  100% {
    transform: translate(77.571499px, 120.869427px);
  }
}

#elv3HKW7FYB151_to {
  animation: elv3HKW7FYB151_to__to 6000ms linear infinite normal forwards;
}
@keyframes elv3HKW7FYB151_to__to {
  0% {
    transform: translate(388.557999px, 152.91px);
  }
  100% {
    transform: translate(60.783001px, 343.158px);
  }
}

#elv3HKW7FYB245_tr {
  animation: elv3HKW7FYB245_tr__tr 6000ms linear infinite normal forwards;
}
@keyframes elv3HKW7FYB245_tr__tr {
  0% {
    transform: translate(149.622004px, 265.429874px) rotate(0deg);
  }
  33.333333% {
    transform: translate(149.622004px, 265.429874px) rotate(8deg);
  }
  66.666667% {
    transform: translate(149.622004px, 265.429874px) rotate(-8deg);
  }
  100% {
    transform: translate(149.622004px, 265.429874px) rotate(0deg);
  }
}

#elv3HKW7FYB252_tr {
  animation: elv3HKW7FYB252_tr__tr 6000ms linear infinite normal forwards;
}
@keyframes elv3HKW7FYB252_tr__tr {
  0% {
    transform: translate(161.512px, 338.33px) rotate(0deg);
  }
  33.333333% {
    transform: translate(161.512px, 338.33px) rotate(8deg);
  }
  66.666667% {
    transform: translate(161.512px, 338.33px) rotate(-8deg);
  }
  100% {
    transform: translate(161.512px, 338.33px) rotate(0deg);
  }
}

#elv3HKW7FYB259_tr {
  animation: elv3HKW7FYB259_tr__tr 6000ms linear infinite normal forwards;
}
@keyframes elv3HKW7FYB259_tr__tr {
  0% {
    transform: translate(214.6225px, 289.089px) rotate(0deg);
  }
  33.333333% {
    transform: translate(214.6225px, 289.089px) rotate(8deg);
  }
  66.666667% {
    transform: translate(214.6225px, 289.089px) rotate(-8deg);
  }
  100% {
    transform: translate(214.6225px, 289.089px) rotate(0deg);
  }
}
