#eJsuVIYA5wG12_to {
  animation: eJsuVIYA5wG12_to__to 4000ms linear infinite normal forwards;
}
@keyframes eJsuVIYA5wG12_to__to {
  0% {
    transform: translate(290.258499px, 141.832546px);
  }
  50% {
    transform: translate(290.258499px, 108.75214px);
  }
  100% {
    transform: translate(290.258499px, 141.83214px);
  }
}
#eJsuVIYA5wG40 {
  animation: eJsuVIYA5wG40_c_o 4000ms linear infinite normal forwards;
}
@keyframes eJsuVIYA5wG40_c_o {
  0% {
    opacity: 1;
  }
  37.5% {
    opacity: 0.25;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
#eJsuVIYA5wG137_ts {
  animation: eJsuVIYA5wG137_ts__ts 4000ms linear infinite normal forwards;
}
@keyframes eJsuVIYA5wG137_ts__ts {
  0% {
    transform: translate(182.294998px, 169.544266px) scale(1, 1);
  }
  37.5% {
    transform: translate(182.294998px, 169.544266px) scale(1.09, 1.09);
  }
  75% {
    transform: translate(182.294998px, 169.544266px) scale(1, 1);
  }
  100% {
    transform: translate(182.294998px, 169.544266px) scale(1, 1);
  }
}
#eJsuVIYA5wG158_ts {
  animation: eJsuVIYA5wG158_ts__ts 4000ms linear infinite normal forwards;
}
@keyframes eJsuVIYA5wG158_ts__ts {
  0% {
    transform: translate(232.525658px, 190.633522px) scale(1, 1);
  }
  37.5% {
    transform: translate(232.525658px, 190.633522px) scale(1.5, 1.5);
  }
  75% {
    transform: translate(232.525658px, 190.633522px) scale(1, 1);
  }
  100% {
    transform: translate(232.525658px, 190.633522px) scale(1, 1);
  }
}
#eJsuVIYA5wG227_tr {
  animation: eJsuVIYA5wG227_tr__tr 4000ms linear infinite normal forwards;
}
@keyframes eJsuVIYA5wG227_tr__tr {
  0% {
    transform: translate(91.159645px, 274.137039px) rotate(0deg);
  }
  37.5% {
    transform: translate(91.159645px, 274.137039px) rotate(10deg);
  }
  75% {
    transform: translate(91.159645px, 274.137039px) rotate(0deg);
  }
  100% {
    transform: translate(91.159645px, 274.137039px) rotate(0deg);
  }
}
