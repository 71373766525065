html {
	scroll-behavior: smooth !important;
	font-family: "Poppins", "Readex Pro", "Noto Sans Kannada", "Roboto", "Helvetica", "Arial", sans-serif !important;
}

body {
	transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
	scrollbar-color: "transparent #ff5724" !important;
	scrollbar-width: thin !important;
}

::-webkit-scrollbar {
	width: 6px;
}

::-webkit-scrollbar-track {
	background: transparent;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: #fc8019 !important;
}

::-webkit-scrollbar-thumb:hover {
	border-radius: 10px;
	background: #ca6614 !important;
}

@keyframes bounce {
	0%,
	20%,
	50%,
	80%,
	100% {
		transform: translateY(0);
	}
	40% {
		transform: translateY(-30px);
	}
	60% {
		transform: translateY(-15px);
	}
}

.grecaptcha-badge {
	visibility: hidden;
}
