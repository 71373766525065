#eAXjxJLz5vH7_ts {
  animation: eAXjxJLz5vH7_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eAXjxJLz5vH7_ts__ts {
  0% {
    transform: translate(321.570999px, 199.895004px) scale(1, 1);
  }
  50% {
    transform: translate(321.570999px, 199.895004px) scale(1.2, 1.2);
  }
  100% {
    transform: translate(321.570999px, 199.895004px) scale(1, 1);
  }
}
#eAXjxJLz5vH11_ts {
  animation: eAXjxJLz5vH11_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eAXjxJLz5vH11_ts__ts {
  0% {
    transform: translate(133.3605px, 89.717942px) scale(1, 1);
  }
  50% {
    transform: translate(133.3605px, 89.717942px) scale(1.1, 1.1);
  }
  100% {
    transform: translate(133.3605px, 89.717942px) scale(1, 1);
  }
}
#eAXjxJLz5vH31_tr {
  animation: eAXjxJLz5vH31_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eAXjxJLz5vH31_tr__tr {
  0% {
    transform: translate(35.724815px, 248.580357px) rotate(0deg);
  }
  50% {
    transform: translate(35.724815px, 248.580357px) rotate(-10deg);
  }
  100% {
    transform: translate(35.724815px, 248.580357px) rotate(0deg);
  }
}
#eAXjxJLz5vH48_tr {
  animation: eAXjxJLz5vH48_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eAXjxJLz5vH48_tr__tr {
  0% {
    transform: translate(30.5508px, 245.98242px) rotate(0deg);
  }
  50% {
    transform: translate(30.5508px, 245.98242px) rotate(5deg);
  }
  100% {
    transform: translate(30.5508px, 245.98242px) rotate(0deg);
  }
}
#eAXjxJLz5vH68_tr {
  animation: eAXjxJLz5vH68_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eAXjxJLz5vH68_tr__tr {
  0% {
    transform: translate(50.8399px, 259.738129px) rotate(0deg);
  }
  50% {
    transform: translate(50.8399px, 259.738129px) rotate(5deg);
  }
  100% {
    transform: translate(50.8399px, 259.738129px) rotate(0deg);
  }
}
#eAXjxJLz5vH98_to {
  animation: eAXjxJLz5vH98_to__to 3000ms linear infinite normal forwards;
}
@keyframes eAXjxJLz5vH98_to__to {
  0% {
    transform: translate(46.745152px, 222.262154px);
  }
  50% {
    transform: translate(46.745152px, 215.576088px);
  }
  100% {
    transform: translate(46.745152px, 222.266088px);
  }
}
#eAXjxJLz5vH115_ts {
  animation: eAXjxJLz5vH115_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eAXjxJLz5vH115_ts__ts {
  0% {
    transform: translate(283.576996px, 265.791px) scale(1, 1);
  }
  50% {
    transform: translate(283.576996px, 265.791px) scale(1.2, 1.2);
  }
  100% {
    transform: translate(283.576996px, 265.791px) scale(1, 1);
  }
}
#eAXjxJLz5vH116_ts {
  animation: eAXjxJLz5vH116_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eAXjxJLz5vH116_ts__ts {
  0% {
    transform: translate(244.053002px, 86.629402px) scale(1, 1);
  }
  50% {
    transform: translate(244.053002px, 86.629402px) scale(1.1, 1.1);
  }
  100% {
    transform: translate(244.053002px, 86.629402px) scale(1, 1);
  }
}
