#ed8V2GaCDnk10_tr {
  animation: ed8V2GaCDnk10_tr__tr 6000ms linear infinite normal forwards;
}
@keyframes ed8V2GaCDnk10_tr__tr {
  0% {
    transform: translate(313.576878px, 235.312125px) rotate(0deg);
  }
  33.333333% {
    transform: translate(313.576878px, 235.312125px) rotate(6deg);
  }
  66.666667% {
    transform: translate(313.576878px, 235.312125px) rotate(-6deg);
  }
  100% {
    transform: translate(313.576878px, 235.312125px) rotate(0deg);
  }
}
#ed8V2GaCDnk15_tr {
  animation: ed8V2GaCDnk15_tr__tr 6000ms linear infinite normal forwards;
}
@keyframes ed8V2GaCDnk15_tr__tr {
  0% {
    transform: translate(289.775148px, 237.902969px) rotate(0deg);
  }
  33.333333% {
    transform: translate(289.775148px, 237.902969px) rotate(6deg);
  }
  66.666667% {
    transform: translate(289.775148px, 237.902969px) rotate(-6deg);
  }
  100% {
    transform: translate(289.775148px, 237.902969px) rotate(0deg);
  }
}
#ed8V2GaCDnk57_tr {
  animation: ed8V2GaCDnk57_tr__tr 6000ms linear infinite normal forwards;
}
@keyframes ed8V2GaCDnk57_tr__tr {
  0% {
    transform: translate(164.9575px, 311.922211px) rotate(0deg);
  }
  33.333333% {
    transform: translate(164.9575px, 311.922211px) rotate(6deg);
  }
  66.666667% {
    transform: translate(164.9575px, 311.922211px) rotate(-6deg);
  }
  100% {
    transform: translate(164.9575px, 311.922211px) rotate(0deg);
  }
}
#ed8V2GaCDnk66_ts {
  animation: ed8V2GaCDnk66_ts__ts 6000ms linear infinite normal forwards;
}
@keyframes ed8V2GaCDnk66_ts__ts {
  0% {
    transform: translate(281.7174px, 144.149216px) scale(1, 1);
  }
  50% {
    transform: translate(281.7174px, 144.149216px) scale(1.1, 1.1);
  }
  100% {
    transform: translate(281.7174px, 144.149216px) scale(1, 1);
  }
}
#ed8V2GaCDnk204_ts {
  animation: ed8V2GaCDnk204_ts__ts 6000ms linear infinite normal forwards;
}
@keyframes ed8V2GaCDnk204_ts__ts {
  0% {
    transform: translate(87.103298px, 90.823948px) scale(1, 1);
  }
  50% {
    transform: translate(87.103298px, 90.823948px) scale(1.5, 1.5);
  }
  100% {
    transform: translate(87.103298px, 90.823948px) scale(1, 1);
  }
}
#ed8V2GaCDnk210_ts {
  animation: ed8V2GaCDnk210_ts__ts 6000ms linear infinite normal forwards;
}
@keyframes ed8V2GaCDnk210_ts__ts {
  0% {
    transform: translate(227.357544px, 69.887323px) scale(1, 1);
  }
  50% {
    transform: translate(227.357544px, 69.887323px) scale(1.25, 1.25);
  }
  100% {
    transform: translate(227.357544px, 69.887323px) scale(1, 1);
  }
}
