#eVwu81mTYGP10_ts {
  animation: eVwu81mTYGP10_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eVwu81mTYGP10_ts__ts {
  0% {
    transform: translate(308.668015px, 119.343876px) scale(1, 1);
  }
  50% {
    transform: translate(308.668015px, 119.343876px) scale(1.15, 1.15);
  }
  100% {
    transform: translate(308.668015px, 119.343876px) scale(1, 1);
  }
}
#eVwu81mTYGP30_ts {
  animation: eVwu81mTYGP30_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eVwu81mTYGP30_ts__ts {
  0% {
    transform: translate(46.401865px, 234.508866px) scale(1, 1);
  }
  50% {
    transform: translate(46.401865px, 234.508866px) scale(1.3, 1.3);
  }
  100% {
    transform: translate(46.401865px, 234.508866px) scale(1, 1);
  }
}
#eVwu81mTYGP35_ts {
  animation: eVwu81mTYGP35_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eVwu81mTYGP35_ts__ts {
  0% {
    transform: translate(51.608237px, 276.383011px) scale(1, 1);
  }
  50% {
    transform: translate(51.608237px, 276.383011px) scale(1.3, 1.3);
  }
  100% {
    transform: translate(51.608237px, 276.383011px) scale(1, 1);
  }
}
#eVwu81mTYGP64_to {
  animation: eVwu81mTYGP64_to__to 3000ms linear infinite normal forwards;
}
@keyframes eVwu81mTYGP64_to__to {
  0% {
    transform: translate(134.305004px, 95.369782px);
  }
  50% {
    transform: translate(134.305004px, 81.844406px);
  }
  100% {
    transform: translate(134.305004px, 95.374406px);
  }
}
#eVwu81mTYGP73_ts {
  animation: eVwu81mTYGP73_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eVwu81mTYGP73_ts__ts {
  0% {
    transform: translate(295.657867px, 238.335693px) scale(1, 1);
  }
  50% {
    transform: translate(295.657867px, 238.335693px) scale(1.5, 1.5);
  }
  100% {
    transform: translate(295.657867px, 238.335693px) scale(1, 1);
  }
}
#eVwu81mTYGP76_ts {
  animation: eVwu81mTYGP76_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eVwu81mTYGP76_ts__ts {
  0% {
    transform: translate(200.182999px, 71.592937px) scale(1, 1);
  }
  50% {
    transform: translate(200.182999px, 71.592937px) scale(1.5, 1.5);
  }
  100% {
    transform: translate(200.182999px, 71.592937px) scale(1, 1);
  }
}
#eVwu81mTYGP79_ts {
  animation: eVwu81mTYGP79_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eVwu81mTYGP79_ts__ts {
  0% {
    transform: translate(93.834099px, 146.482002px) scale(1, 1);
  }
  50% {
    transform: translate(93.834099px, 146.482002px) scale(1.5, 1.5);
  }
  100% {
    transform: translate(93.834099px, 146.482002px) scale(1, 1);
  }
}
#eVwu81mTYGP84_ts {
  animation: eVwu81mTYGP84_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eVwu81mTYGP84_ts__ts {
  0% {
    transform: translate(204.803917px, 247.331963px) scale(1, 1);
  }
  50% {
    transform: translate(204.803917px, 247.331963px) scale(1.5, 1.5);
  }
  100% {
    transform: translate(204.803917px, 247.331963px) scale(1, 1);
  }
}
#eVwu81mTYGP103_ts {
  animation: eVwu81mTYGP103_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eVwu81mTYGP103_ts__ts {
  0% {
    transform: translate(241.953514px, 223.434502px) scale(1, 1);
  }
  50% {
    transform: translate(241.953514px, 223.434502px) scale(1.3, 1.3);
  }
  100% {
    transform: translate(241.953514px, 223.434502px) scale(1, 1);
  }
}
#eVwu81mTYGP123_ts {
  animation: eVwu81mTYGP123_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eVwu81mTYGP123_ts__ts {
  0% {
    transform: translate(186.712868px, 160.167019px) scale(1, 1);
  }
  50% {
    transform: translate(186.712868px, 160.167019px) scale(1.09, 1.09);
  }
  100% {
    transform: translate(186.712868px, 160.167019px) scale(1, 1);
  }
}
#eVwu81mTYGP144_tr {
  animation: eVwu81mTYGP144_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eVwu81mTYGP144_tr__tr {
  0% {
    transform: translate(139.308914px, 193.610466px) rotate(0deg);
  }
  50% {
    transform: translate(139.308914px, 193.610466px) rotate(-15deg);
  }
  100% {
    transform: translate(139.308914px, 193.610466px) rotate(0deg);
  }
}
#eVwu81mTYGP174_tr {
  animation: eVwu81mTYGP174_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eVwu81mTYGP174_tr__tr {
  0% {
    transform: translate(87.076637px, 165.291px) rotate(0deg);
  }
  50% {
    transform: translate(87.076637px, 165.291px) rotate(15deg);
  }
  100% {
    transform: translate(87.076637px, 165.291px) rotate(0deg);
  }
}
#eVwu81mTYGP209_tr {
  animation: eVwu81mTYGP209_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eVwu81mTYGP209_tr__tr {
  0% {
    transform: translate(102.734274px, 191.961823px) rotate(0deg);
  }
  50% {
    transform: translate(102.734274px, 191.961823px) rotate(5deg);
  }
  100% {
    transform: translate(102.734274px, 191.961823px) rotate(0deg);
  }
}
