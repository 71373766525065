#e0NGXiUIfWb11 {
  animation: e0NGXiUIfWb11_c_o 6000ms linear infinite normal forwards;
}
@keyframes e0NGXiUIfWb11_c_o {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
#e0NGXiUIfWb15 {
  animation: e0NGXiUIfWb15_c_o 6000ms linear infinite normal forwards;
}
@keyframes e0NGXiUIfWb15_c_o {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  35% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
#e0NGXiUIfWb19 {
  animation: e0NGXiUIfWb19_c_o 6000ms linear infinite normal forwards;
}
@keyframes e0NGXiUIfWb19_c_o {
  0% {
    opacity: 0;
  }
  35% {
    opacity: 0;
  }
  51.666667% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
#e0NGXiUIfWb23 {
  animation: e0NGXiUIfWb23_c_o 6000ms linear infinite normal forwards;
}
@keyframes e0NGXiUIfWb23_c_o {
  0% {
    opacity: 0;
  }
  51.666667% {
    opacity: 0;
  }
  68.333333% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
#e0NGXiUIfWb27 {
  animation: e0NGXiUIfWb27_c_o 6000ms linear infinite normal forwards;
}
@keyframes e0NGXiUIfWb27_c_o {
  0% {
    opacity: 0;
  }
  68.333333% {
    opacity: 0;
  }
  85% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
#e0NGXiUIfWb36_tr {
  animation: e0NGXiUIfWb36_tr__tr 6000ms linear infinite normal forwards;
}
@keyframes e0NGXiUIfWb36_tr__tr {
  0% {
    transform: translate(50.807px, 37.68298px) rotate(0deg);
  }
  33.333333% {
    transform: translate(50.807px, 37.68298px) rotate(-15deg);
  }
  66.666667% {
    transform: translate(50.807px, 37.68298px) rotate(15deg);
  }
  100% {
    transform: translate(50.807px, 37.68298px) rotate(0deg);
  }
}
#e0NGXiUIfWb45_ts {
  animation: e0NGXiUIfWb45_ts__ts 6000ms linear infinite normal forwards;
}
@keyframes e0NGXiUIfWb45_ts__ts {
  0% {
    transform: translate(26.570851px, 106.280476px) scale(1, 1);
  }
  50% {
    transform: translate(26.570851px, 106.280476px) scale(1.15, 1.15);
  }
  100% {
    transform: translate(26.570851px, 106.280476px) scale(1, 1);
  }
}
