#exyWzUZoCjF3_to {
  animation: exyWzUZoCjF3_to__to 2500ms linear infinite normal forwards
}

@keyframes exyWzUZoCjF3_to__to {
  0% {
    transform: translate(127.552659px, 127.969082px)
  }

  100% {
    transform: translate(-141.874494px, 366.744064px)
  }
}

#exyWzUZoCjF4_to {
  animation: exyWzUZoCjF4_to__to 2500ms linear infinite normal forwards
}

@keyframes exyWzUZoCjF4_to__to {
  0% {
    transform: translate(398.747659px, -111.86092px)
  }

  100% {
    transform: translate(129.320506px, 126.914062px)
  }
}