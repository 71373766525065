/* devanagari */
@font-face {
	font-family: "Poppins";
	font-style: italic;
	font-weight: 300;
	font-display: swap;
	src: url(https://cdn.beegru.com/fonts/poppins/1.woff2) format("woff2");
	unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
	font-family: "Poppins";
	font-style: italic;
	font-weight: 300;
	font-display: swap;
	src: url(https://cdn.beegru.com/fonts/poppins/2.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: "Poppins";
	font-style: italic;
	font-weight: 300;
	font-display: swap;
	src: url(https://cdn.beegru.com/fonts/poppins/3.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
	font-family: "Poppins";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(https://cdn.beegru.com/fonts/poppins/4.woff2) format("woff2");
	unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
	font-family: "Poppins";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(https://cdn.beegru.com/fonts/poppins/5.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: "Poppins";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(https://cdn.beegru.com/fonts/poppins/6.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
	font-family: "Poppins";
	font-style: italic;
	font-weight: 500;
	font-display: swap;
	src: url(https://cdn.beegru.com/fonts/poppins/7.woff2) format("woff2");
	unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
	font-family: "Poppins";
	font-style: italic;
	font-weight: 500;
	font-display: swap;
	src: url(https://cdn.beegru.com/fonts/poppins/8.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: "Poppins";
	font-style: italic;
	font-weight: 500;
	font-display: swap;
	src: url(https://cdn.beegru.com/fonts/poppins/9.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
	font-family: "Poppins";
	font-style: italic;
	font-weight: 600;
	font-display: swap;
	src: url(https://cdn.beegru.com/fonts/poppins/10.woff2) format("woff2");
	unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
	font-family: "Poppins";
	font-style: italic;
	font-weight: 600;
	font-display: swap;
	src: url(https://cdn.beegru.com/fonts/poppins/11.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: "Poppins";
	font-style: italic;
	font-weight: 600;
	font-display: swap;
	src: url(https://cdn.beegru.com/fonts/poppins/12.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
	font-family: "Poppins";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url(https://cdn.beegru.com/fonts/poppins/13.woff2) format("woff2");
	unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
	font-family: "Poppins";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url(https://cdn.beegru.com/fonts/poppins/14.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: "Poppins";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url(https://cdn.beegru.com/fonts/poppins/15.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url(https://cdn.beegru.com/fonts/poppins/16.woff2) format("woff2");
	unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url(https://cdn.beegru.com/fonts/poppins/17.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url(https://cdn.beegru.com/fonts/poppins/18.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://cdn.beegru.com/fonts/poppins/19.woff2) format("woff2");
	unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://cdn.beegru.com/fonts/poppins/20.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://cdn.beegru.com/fonts/poppins/21.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url(https://cdn.beegru.com/fonts/poppins/22.woff2) format("woff2");
	unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url(https://cdn.beegru.com/fonts/poppins/23.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url(https://cdn.beegru.com/fonts/poppins/24.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url(https://cdn.beegru.com/fonts/poppins/25.woff2) format("woff2");
	unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url(https://cdn.beegru.com/fonts/poppins/26.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url(https://cdn.beegru.com/fonts/poppins/27.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://cdn.beegru.com/fonts/poppins/28.woff2) format("woff2");
	unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://cdn.beegru.com/fonts/poppins/29.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://cdn.beegru.com/fonts/poppins/30.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
