#eSvNY45AWR6102_ts {
  animation: eSvNY45AWR6102_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eSvNY45AWR6102_ts__ts {
  0% {
    transform: translate(157.82013px, 193.274994px) scale(1, 1);
  }
  50% {
    transform: translate(157.82013px, 193.274994px) scale(1.4, 1.4);
  }
  100% {
    transform: translate(157.82013px, 193.274994px) scale(1, 1);
  }
}

#eSvNY45AWR6151_ts {
  animation: eSvNY45AWR6151_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eSvNY45AWR6151_ts__ts {
  0% {
    transform: translate(77.9263px, 120.479515px) scale(1, 1);
  }
  50% {
    transform: translate(77.9263px, 120.479515px) scale(0.75, 0.75);
  }
  100% {
    transform: translate(77.9263px, 120.479515px) scale(1, 1);
  }
}

#eSvNY45AWR6158_ts {
  animation: eSvNY45AWR6158_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eSvNY45AWR6158_ts__ts {
  0% {
    transform: translate(237.952309px, 186.153336px) scale(1, 1);
  }
  50% {
    transform: translate(237.952309px, 186.153336px) scale(1.5, 1.5);
  }
  100% {
    transform: translate(237.952309px, 186.153336px) scale(1, 1);
  }
}

#eSvNY45AWR6159_ts {
  animation: eSvNY45AWR6159_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eSvNY45AWR6159_ts__ts {
  0% {
    transform: translate(36.906902px, 173.992501px) scale(1, 1);
  }
  50% {
    transform: translate(36.906902px, 173.992501px) scale(0.75, 0.75);
  }
  100% {
    transform: translate(36.906902px, 173.992501px) scale(1, 1);
  }
}

#eSvNY45AWR6166_to {
  animation: eSvNY45AWR6166_to__to 3000ms linear infinite normal forwards;
}
@keyframes eSvNY45AWR6166_to__to {
  0% {
    transform: translate(262.106499px, 242.039459px);
  }
  50% {
    transform: translate(262.106499px, 229.230835px);
  }
  100% {
    transform: translate(262.106499px, 242.040835px);
  }
}

#eSvNY45AWR6171_to {
  animation: eSvNY45AWR6171_to__to 3000ms linear infinite normal forwards;
}
@keyframes eSvNY45AWR6171_to__to {
  0% {
    transform: translate(148.468994px, 101.053742px);
  }
  50% {
    transform: translate(148.468994px, 88.239784px);
  }
  100% {
    transform: translate(148.468994px, 101.049784px);
  }
}
