#e8TB5QEo4qs73_ts {
  animation: e8TB5QEo4qs73_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes e8TB5QEo4qs73_ts__ts {
  0% {
    transform: translate(304.511993px, 32.76065px) scale(1, 1);
  }
  50% {
    transform: translate(304.511993px, 32.76065px) scale(1.15, 1.15);
  }
  100% {
    transform: translate(304.511993px, 32.76065px) scale(1, 1);
  }
}

#e8TB5QEo4qs86_to {
  animation: e8TB5QEo4qs86_to__to 3000ms linear infinite normal forwards;
}
@keyframes e8TB5QEo4qs86_to__to {
  0% {
    transform: translate(288.414001px, 18.505567px);
  }
  50% {
    transform: translate(288.414001px, 25.225323px);
  }
  100% {
    transform: translate(288.414001px, 19.004463px);
  }
}

#e8TB5QEo4qs111_to {
  animation: e8TB5QEo4qs111_to__to 3000ms linear infinite normal forwards;
}
@keyframes e8TB5QEo4qs111_to__to {
  0% {
    transform: translate(273.293488px, 64.344801px);
  }
  50% {
    transform: translate(260.723774px, 57.015649px);
  }
  100% {
    transform: translate(273.43049px, 64.348946px);
  }
}

#e8TB5QEo4qs112_to {
  animation: e8TB5QEo4qs112_to__to 3000ms linear infinite normal forwards;
}
@keyframes e8TB5QEo4qs112_to__to {
  0% {
    transform: translate(270.774994px, 65.515301px);
  }
  50% {
    transform: translate(258.205278px, 58.18615px);
  }
  100% {
    transform: translate(270.911994px, 65.51944px);
  }
}

#e8TB5QEo4qs114_to {
  animation: e8TB5QEo4qs114_to__to 3000ms linear infinite normal forwards;
}
@keyframes e8TB5QEo4qs114_to__to {
  0% {
    transform: translate(273.293488px, 62.989252px);
  }
  50% {
    transform: translate(260.723774px, 55.660104px);
  }
  100% {
    transform: translate(273.43049px, 62.993408px);
  }
}

#e8TB5QEo4qs117_to {
  animation: e8TB5QEo4qs117_to__to 3000ms linear infinite normal forwards;
}
@keyframes e8TB5QEo4qs117_to__to {
  0% {
    transform: translate(269.7285px, 52.750051px);
  }
  50% {
    transform: translate(282.073498px, 45.075601px);
  }
  100% {
    transform: translate(269.592498px, 52.769501px);
  }
}

#e8TB5QEo4qs119_to {
  animation: e8TB5QEo4qs119_to__to 3000ms linear infinite normal forwards;
}
@keyframes e8TB5QEo4qs119_to__to {
  0% {
    transform: translate(272.246994px, 53.9205px);
  }
  50% {
    transform: translate(284.591994px, 46.24605px);
  }
  100% {
    transform: translate(272.110994px, 53.93995px);
  }
}

#e8TB5QEo4qs120_to {
  animation: e8TB5QEo4qs120_to__to 3000ms linear infinite normal forwards;
}
@keyframes e8TB5QEo4qs120_to__to {
  0% {
    transform: translate(269.7285px, 51.394651px);
  }
  50% {
    transform: translate(282.073498px, 43.720201px);
  }
  100% {
    transform: translate(269.592498px, 51.414101px);
  }
}

#e8TB5QEo4qs122_to {
  animation: e8TB5QEo4qs122_to__to 3000ms linear infinite normal forwards;
}
@keyframes e8TB5QEo4qs122_to__to {
  0% {
    transform: translate(169.29282px, 105.141609px);
  }
  50% {
    transform: translate(169.29282px, 93.884384px);
  }
  100% {
    transform: translate(169.29282px, 105.144384px);
  }
}
